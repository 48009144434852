<template>
  <div>
    <section class="myNavbar d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <b-container fluid>
        <b-row>
          <b-col cols="6" sm="6" md="6" lg="6" xl="9" class="pl-0 pr-0">
            <div
              style="
                width: 100%;
                height: 100px;
                background-color: ;
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <b-avatar
                rounded="lg"
                variant="secondary"
                size="3rem"
                :src="statePict"
              ></b-avatar>

              <div class="profilenya">
                <span class="nama">{{ stateProfile.nama }}</span>
                <span class="email">{{ stateProfile.email }}</span>
              </div>
            </div>
          </b-col>
          <b-col cols="6" sm="6" md="6" lg="6" xl="3" class="pl-0 pr-0">
            <div
              style="
                width: 100%;
                height: 100px;
                background-color: ;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span>
                <h6
                  style="
                    font-size: 18px;
                    font-weight: 700;
                    font-family: nunito;
                    color: #fff;
                    cursor:pointer
                  "
                  class="mt-0 mb-0"
                  @click="goDash"
                >
                  Dashboard
                </h6>
              </span>
              <span
                ><h6
                  style="
                    font-size: 18px;
                    font-weight: 700;
                    font-family: nunito;
                    color: #fff;
                    cursor:pointer
                  "
                  class="mt-0 mb-0"
                  @click="goTo"
                >
                  Profile
                </h6></span
              >
              <span
                ><h6
                  style="
                    font-size: 18px;
                    font-weight: 700;
                    font-family: nunito;
                    color: #fff;
                    cursor:pointer
                  "
                  class="mt-0 mb-0"
                  @click="openModals"
                >
                  Sign Out
                </h6></span
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!---------------------------------------------- MODALS ---------------------------------------------->
    <b-modal id="modal-logout" centered hide-footer hide-header>
      <div
        class="text"
        style="text-align: center; margin-top: 2%; border: none"
      >
        <span
          style="
            font-family: 'nunito', sans-serif;
            color: #061737;
            font-size: 26px;
            font-weight: 700;
          "
          >Apakah Anda ingin melanjutkan <br />
          SIGN OUT?</span
        >

        <div class="button-wrapper" style="margin: 4%">
          <b-button
            @click="closeModals"
            style="
              margin: 0 2%;
              width: 100px;
              background-color: #e0544e;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
            "
            >Tidak</b-button
          >
          <b-button
            @click="doLogout"
            style="
              margin: 0 2%;
              width: 100px;
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
            "
            >Ya</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  beforeMount() {
    this.$store.dispatch("apiUser/getUser");
  },
  computed: {
    ...mapState("apiUser", [
      "stateProfile",
      "stateId",
      "statePict",
      "loadingProfile",
    ]),
  },

  methods: {
    openModals() {
      this.$root.$emit("bv::show::modal", "modal-logout");
    },

    closeModals() {
      this.$root.$emit("bv::hide::modal", "modal-logout");
    },

    doLogout() {
      localStorage.removeItem("idClient");
      localStorage.removeItem("idKAP");
      localStorage.removeItem("role");
      localStorage.removeItem("namaClient");
      localStorage.removeItem("idProject");
      localStorage.removeItem("idThread");
      localStorage.removeItem("tahunProject");
      localStorage.removeItem("token");
      this.$router.push("/");
    },

    goTo() {
      this.$router.push("/profile");
    },

    goDash() {
      this.$router.push("/main_dashboard");
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
);

// .navbar {
//   background-color: map-get($color, "blue") !important;
//   background-image: url("../assets/vector/Background.png");
//   // background-position: center;
//   // background-blend-mode: soft-light;
//   background-repeat: repeat;
//   padding: 2%;
//   .profilenya {
//     margin-left: 1%;
//     display: flex;
//     flex-direction: column;
//     .nama {
//       font-family: "nunito", sans-serif;
//       color: map-get($color, "white");
//       font-size: 20px;
//       font-weight: 700;
//       text-transform: capitalize;
//     }
//     .email {
//       font-family: "nunito", sans-serif;
//       color: map-get($color, "white");
//       font-size: 16px;
//       font-weight: 500;
//     }
//   }

//   ul {
//     li {
//       a {
//         font-family: "nunito", sans-serif !important;
//         color: map-get($color, "white") !important;
//         font-weight: 700;
//         font-size: 18px;
//       }
//     }
//   }
// }

.myNavbar {
  background-color: map-get($color, "blue") !important;
  background-image: url("../assets/vector/Background.png");
  // background-position: center;
  // background-blend-mode: soft-light;
  background-repeat: repeat;
  padding: 0 2%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  .profilenya {
    margin-left: 1%;
    display: flex;
    flex-direction: column;
    .nama {
      font-family: "nunito", sans-serif;
      color: map-get($color, "white");
      font-size: 20px;
      font-weight: 700;
      text-transform: capitalize;
    }
    .email {
      font-family: "nunito", sans-serif;
      color: map-get($color, "white");
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>
